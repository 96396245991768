const options = {
  position: 'top-right',
  duration: 3000,
  dismissible: false,
  queue: false,
  pauseOnHover: true
};

const messages = {
  common: {
    clipboard: {
      success: 'Copied',
      error: 'Copy not supported on your browser'
    },
    error: 'Υπήρξε κάποιο πρόβλημα. Προσπαθήστε ξανά'
  },
  auth: {
    login: {
      success: 'Επιτυχής σύνδεση',
      error: {
        wrongPassword: 'Λάθος κωδικός',
        userNotFound: 'Ο χρήστης δεν βρέθηκε'
      }
    },
    register: {
      error: {
        userExist: 'Υπάρχει ήδη χρήστης με αυτό το email',
        invalidEmail: 'Το email δεν είναι σωστό',
        invalidCredentials: 'Λάθος δεδομένα εγγραφής'
      }
    }
  },
  contact: {
    success: 'Το μήνυμα στάλθηκε'
  },
  promoCodes: {
    error: {
      notFound: 'Ο κωδικός δεν βρέθηκε'
    }
  }
};

export { options, messages };
